import React from "react";
import "../App.css";
import Landing from "../Components/Landing";

function Home() {
  return (
    <>
      <Landing />
      <section id="home">
        <div className="wrapper">
            <div className="content">
                <h1>Work in Progress</h1>
                <p>
                    This website is still a work in progress, please check back later for more content.
                    Don't worry, I hate this homepage too.. I would rather work on other projects then update it. 
                    My code speaks for itself, I dont need a flashy website to do that.
                    #Priorities
                </p>
                <p><a href="https://github.com/Alx-Benjamin">github.com/Alx-Benjamin</a></p>
            </div>
        </div>
      </section>
    </>
  );
}

export default Home;