import React, { useState, useEffect } from "react";
import Footer from "../Components/Footer";
import { Parallax } from "react-parallax";
import surfpic from "../Assets/surf.gif";
import top500 from "../Assets/top500.gif";
import ghost from "../Assets/ghost.gif";
import myheart from "../Assets/myheart.png";

function Bio() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Parallax
        bgImage={surfpic}
        strength={200}
        renderLayer={percentage => (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              width: percentage * (isMobile ? 300 : 500),
              height: percentage * (isMobile ? 300 : 500)
            }}
          />
        )}
      >
        <div style={{minHeight: isMobile ? '300px' : '500px'}}></div>
      </Parallax>
      
      <center><h1 class="hero glitch layers" data-text="近設計"><span>Who Am I? </span></h1></center>
      <br/>
      <div style={{width: '80%', margin: '0 auto', display: isMobile ? 'block' : 'flex'}}>
      
      <img src={myheart} alt="my spouse and I" style={{marginRight: '20px', height: '200px', width: '200px', display: 'block', marginLeft: isMobile ? 'auto' : '0', marginRight: isMobile ? 'auto' : '20px'}}/>
  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
  <p>My name is Alx (Alex with no E) and yes, that is me in the gif.</p>
  <p>I was put on this planet to be the comedic relief, and to roast your code (Dont worry, mine sucks too). I have a love for making complicated or tedious tasks simple. I do not like doing anything mundane more than once; by the second time I touch something, it will be either fixed or automated. This has pushed me to chase after deep knowledge in software development. I do not care what the syntax is, as I feel my understanding of coding and good groundwork is the key to creating something worth sharing.</p>
  <p>My cat, who doesn't love me, has taught me patience. My snake has shown me how dumb reptiles are. My spiders have shown me that personal space is important, and my scorpion has shown me that he will fight even god himself.</p>
        </div>
      </div>
      <center><img src={ghost} alt="Destiny 2 Ghost" style={{marginRight: '20px', height: '200px', width: '200px'}}/>
      <div style={{width: '80%', margin: '0 auto', display: 'flex', flexDirection: 'row-reverse'}}>
      
  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
  <p>If I am not dealing with compiling errors, I can be found developing carpal tunnel in pursuit of leaderboards in Overwatch, Diablo, Destiny 2, or Apex Legends. I used to coach an Overwatch team and have dabbled in the Esports scene. I pride myself in peaking at Rank 53 of all players (Top 20 in the US) #GenjiMain</p>
      <p>When I give my eyes a break from screens, you can find my fiancée and I lost in the woods, hiking, or staring at geese.</p>
      <p>I very much enjoy being out of the house, especially if it involves seeing local music or festivals. Other than that, I am an avid Yugioh collector.</p>
        </div>
      </div>
     <img src={top500} alt="Where you will find me" style={{height: '100px', width: '100px'}}/></center>
      <Footer/>
    </>
  );
}

export default Bio;
