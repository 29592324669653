import React from "react";
import "../App.css";
import "./Footer.css";

function Footer() {
  return (
    <>
    <div className="spacer"></div>
      <div className="footer">Alx Benjamin | Repeat Nothing, Automate Everything</div>
    </>
  );
}

export default Footer;