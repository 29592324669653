import './Landing.css';

  const Landing = props => {
    return (
        <>
      <header id="welcome-section">
        <div className="silhouette"/>
        {
        //<div className="moon" /> goodbye moon.
        }
        <container>
        <span className="line">
              <h1 style={{fontWeight: 'bold'}}>Repeat Nothing</h1>
              <h1 className="magic-text">Automate Everything</h1>
              <h4 style={{fontWeight: 'bold'}}>Scroll Down for More info</h4>
        </span>
        </container>
      </header>
      </>
    );
  };

export default Landing;