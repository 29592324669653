import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

function ProjectCard({ Name, Description, Image, Repo, Website, status }) {  // Destructure props
    return (
        <Card style={{ width: '18rem', float: 'left', padding: '5px', margin: '5px'}}>
            <Card.Img variant="top" src={Image} style={{height: '250px', width: '250px', objectFit: 'scale-down'}} />
            <Card.Body>
                <Card.Title>{Name}</Card.Title>
                <Card.Text>
                    {Description}
                </Card.Text>
                {Repo === 'private' ? (
                    <Alert variant="info">
                        Private / Company Repo
                    </Alert>
                ) : Repo && (
                    <Button style={{margin: '1px'}} variant="primary" href={Repo} target="_blank">Repo</Button>
                )}

                {Website ? (
                    <Button style={{margin: '1px'}} variant="primary" href={Website} target="_blank">Website</Button>
                ) : null}  {/* Correctly return null */}
            </Card.Body>
        </Card>
    );
}

export default ProjectCard;
