import { Navbar, Nav, Container } from 'react-bootstrap';
import { useState } from 'react';
import './NavigationMenu.css';
import logo from '../Assets/ghost.png';

function NavigationMenu({ setPage }) {
    const [expanded, setExpanded] = useState(false);

    return (
    <>
        <Navbar className='navbar' collapseOnSelect fixed='top' expand='sm' expanded={expanded}>
            <Container>
            <Navbar.Brand style={{color: 'white'}}>
                        <img
                            alt=""
                            src={logo}
                            width="auto"
                            height="30"
                            className="d-inline-block align-top"
                            padding-right="10px"
                            margin-right="10px"
                        />
                    </Navbar.Brand>
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls='responsive-navbar-nav'/>
            <Navbar.Collapse id='responsive-navbar-nav'>
                <Nav>
                    <Nav.Link onClick={() => {setPage('home'); setExpanded(false);}}>Home</Nav.Link>
                    <Nav.Link onClick={() => {setPage('bio'); setExpanded(false);}}>Bio</Nav.Link>
                    <Nav.Link onClick={() => {setPage('experience'); setExpanded(false);}}>Experience</Nav.Link>
                    <Nav.Link onClick={() => {setPage('projects'); setExpanded(false);}}>Projects</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar>
    </>
    );
}

export default NavigationMenu;
