// Projects.js
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProjectCard from "../Components/ProjectCard";
import { Stack } from "react-bootstrap";
import Footer from "../Components/Footer";

function Projects() {
  const powershellProjects = [
    {
      Name: "DAT Utility",
      Description: "App to support the DAT team",
      Image: require("../Assets/datutility.png"),
      Repo: "private",
      Website: "",
    },
    {
      Name: "Budgeting App",
      Description: "An old app I still use to help budget all my finances",
      Image: require("../Assets/ghost.png"),
      Repo: "private", 
      Website: "",
    },
    {
      Name: "DinnerBell",
      Description: "A Proof of concept to show what Powershell & winForms is capable of.",
      Image: require("../Assets/dinner.png"),
      Repo: "https://github.com/Alx-Benjamin/Dinner-Bell",
      Website: "",
    },
    {
      Name: "Nexthink Remote Actions",
      Description: "Self fixes for our help desks and users. Most of these are in Powershell, but also in Bash.",
      Image: require("../Assets/nexthink.png"),
      Repo: "private",
      Website: "",
    },
    {
      Name: "Azure Automation",
      Description: "Server and Reporting automation. Intergrations with many, many APIs.",
      Image: require("../Assets/azure.png"),
      Repo: "private",
      Website: "",
    },
  ];

  const csharpProjects = [
    {
      Name: "Deadshot Apocalyse",
      Description: "Online Co-op game about a zombie apocalypse",
      Image: require("../Assets/deadshot.png"),
      Repo: "",
      Website: "https://deadshotapocalypse.com/", 
    },
    {
      Name: "Scarlet Dawn",
      Description: "Single Player game about hunting vampires",
      Image: require("../Assets/ghost.png"),
      Repo: "",
      Website: "", 
    },
    {
      Name: "Dragons Keep Online",
      Description: "Massivly Multiplayer Role Playing game set in a high fantasy world",
      Image: require("../Assets/dragonskeep.png"),
      Repo: "",
      Website: "https://dragonskeeponline.com", 
    },
  ];

  const javascriptProjects = [
    {
      Name: "This Website",
      Description: "This website",
      Image: require("../Assets/ghost.png"),
      Repo: "private",
      Website: "", 
    },
    {
      Name: "Software Request Portal",
      Description: "Designed the UI for Best Buys internal software management platform",
      Image: require("../Assets/bestbuy.png"),
      Repo: "private",
      Website: "", 
    },
    {
      Name: "Best Buy AI",
      Description: "Designed the UI for Best Buys 'Digital Accelerator' AI project",
      Image: require("../Assets/ai.png"),
      Repo: "private",
      Website: "", 
    },
    {
      Name: "Virtual Agent",
      Description: "Chatbot built into the Service-Now platform. Advanced AI and Device remote actions built by me, nothing out of box.",
      Image: require("../Assets/snow.png"),
      Repo: "private",
      Website: "", 
    },
  ];

  const pythonProjects = [
    {
      Name: "Best Buy AI",
      Description: "Worked on core features of the AI, leveraging use of Chroma DB, Google Cloud Authentication, Flask and Cockroach DB",
      Image: require("../Assets/ai.png"),
      Repo: "private",
      Website: "", 
    },
    {
      Name: "Adventure Quest 3D Bot",
      Description: "Proof of Concept bot for an MMORPG. Strictly used to test my Python skills.",
      Image: require("../Assets/bot.png"),
      Repo: "https://github.com/Alx-Benjamin/AQ3D-Bot",
      Website: "", 
    },
    {
      Name: "Personal Scalping",
      Description: "I wanted 'The Legend of Zelda: Tears of the Kingdom'.. So I got it without paying a 're-seller'",
      Image: require("../Assets/ghost.png"),
      Repo: "private",
      Website: "", 
    },
  ];

  const webAppProjects = [
    {
      Name: "Service-Now",
      Description: "I work with Low Environment stuff in SNOW on a daily basis. SNOW AI, custom forms and scripts.",
      Image: require("../Assets/snow.png"),
      Repo: "", 
      Website: "", 
    },
    {
      Name: "Azure Cloud",
      Description: "Specifically server automation and custom alert systems for various teams.",
      Image: require("../Assets/azure.png"),
      Repo: "", 
      Website: "", 
    },
    {
      Name: "Kibana",
      Description: "logs, logs, logs, yes your accounts locked because you mistyped your password 5 times",
      Image: require("../Assets/kibana.png"),
      Repo: "", 
      Website: "", 
    },
    {
      Name: "Jenkins",
      Description: "I only use this because Best Buy insists.. I prefer Github Deploy and Actions",
      Image: require("../Assets/jenkins.png"),
      Repo: "", 
      Website: "", 
    },
    {
      Name: "Github Deploy / Actions",
      Description: "You suck Jenkins. I use this for personal projects, typically mixed with IONOS web/server hosting.",
      Image: require("../Assets/github.png"),
      Repo: "", 
      Website: "", 
    },
    {
      Name: "Nexthink Cloud",
      Description: "Best Buy is Nexthinks biggest customer, we typically are the reason anything gets changed with it.",
      Image: require("../Assets/nexthink.png"),
      Repo: "", 
      Website: "", 
    },
  ];

  // ... (Define other project categories similarly)
  const adminProjects = [
    {
      Name: "Active Directory",
      Description: "Big fan of AD stuff, however.. Intune seems to be the future.",
      Image: require("../Assets/ad.png"),
      Repo: "", 
      Website: "", 
    },
    {
      Name: "Intune",
      Description: "Used this to keep all of our windows machines in check, as well as our macs because jamf is so bad.",
      Image: require("../Assets/intune.png"),
      Repo: "", 
      Website: "", 
    },
    {
      Name: "Jamf",
      Description: "Used for managing macs for about 3 years, got this software is awful. I know how to use it well, but dont like it.",
      Image: require("../Assets/jamf.png"),
      Repo: "", 
      Website: "", 
    },
  ];

  const managementProjects = [
    {
      Name: "Jira",
      Description: "'I will just put a card on the backlog' is my goto phrase. Seriously though, this app is a person with ADHDs saving grace.",
      Image: require("../Assets/jira.png"),
      Repo: "", 
      Website: "", 
    },
    {
      Name: "Confluence",
      Description: "My documentation skills for projects I work on actually make it look like I know what I am doing.",
      Image: require("../Assets/conf.png"),
      Repo: "", 
      Website: "", 
    },
  ];

  const desktopAppProjects = [
    {
      Name: "Adobe Suite",
      Description: "I use Photoshop, After Effects, Premiere, Dreamweaver, and other apps from Adobe weekly.",
      Image: require("../Assets/adobe.png"),
      Repo: "", 
      Website: "", 
    },
    {
      Name: "Powershell Studio",
      Description: "I love this app, its my daily driver for mocking up tools",
      Image: require("../Assets/pss.png"),
      Repo: "", 
      Website: "", 
    },
    {
      Name: "Visual Studio & VS Code",
      Description: "VIM sucks, I will die on that hill.",
      Image: require("../Assets/vs.png"),
      Repo: "", 
      Website: "", 
    },
    {
      Name: "Github Desktop",
      Description: "You can't mistype a push command to the wrong branch with the GUI Version",
      Image: require("../Assets/github.png"),
      Repo: "", 
      Website: "", 
    },
    {
      Name: "Office Suite",
      Description: "I have been deemed as the Excel Wizard.. Check out my Yugioh Collection Tracker (Google Sheets, but same thing)",
      Image: require("../Assets/office.png"),
      Repo: "", 
      Website: "https://docs.google.com/spreadsheets/d/1f1gZ2A3kL823QNk-YupF5s1y4J4sII8-kXiUQws50EY/edit?usp=sharing",
    },
  ];

  const graphicDesignProjects = [
    {
      Name: "UI/UX",
      Description: "All the logos of my gaming projects, I made those. my gaming project sites were made by me.",
      Image: require("../Assets/ghost.png"),
      Repo: "", 
      Website: "", 
    },
    {
      Name: "Art?",
      Description: "I draw.. sometimes. This was my 'Tim Burton' attempt. Also, see that ghost.. I drew that too. ",
      Image: require("../Assets/Alx_burtonstyle.png"),
      Repo: "", 
      Website: "", 
    },
  ];

  const videoEditingProjects = [
    {
      Name: "C Tier Youtuber",
      Description: "If you like Overwatch, you might like my 'Channel'",
      Image: require("../Assets/ghost.png"),
      Repo: "", 
      Website: "https://www.youtube.com/channel/UCPZaORa6CW00VTd7MXa90tQ", 
    },
  ];

  const miscProjects = [
    {
      Name: "3D Printing",
      Description: "Yes I have a drawer of benchies, leave me alone.",
      Image: require("../Assets/ghost.png"),
      Repo: "", 
      Website: "", 
    },
  ];

  return (
    <>
      <div className="main-content">
        <Stack gap={3}>
          {/* Code Based Projects */}
          <div className="p-2">
            <h1 className="hero glitch layers" data-text="近設計">
              <span>Code Based Projects</span>
            </h1>

            <Tabs
              defaultActiveKey="powershell"
              id="projects-tab"
              className="mb-3"
              justify
            >
              <Tab eventKey="powershell" title="Powershell">
                {powershellProjects.map((project) => (
                  <ProjectCard key={project.Name} {...project} />
                ))}
              </Tab>
              <Tab eventKey="csharp" title="C#">
                {csharpProjects.map((project) => (
                  <ProjectCard key={project.Name} {...project} />
                ))}
              </Tab>
              <Tab eventKey="javascript" title="JavaScript"> {/* Updated to JavaScript */}
                {javascriptProjects.map((project) => (
                  <ProjectCard key={project.Name} {...project} />
                ))}
              </Tab>
              <Tab eventKey="python" title="Python">
                {pythonProjects.map((project) => (
                  <ProjectCard key={project.Name} {...project} />
                ))}
              </Tab>
            </Tabs>
          </div>

          {/* Application Familiarity */}
          <div className="p-2">
            <h1 className="hero glitch layers" data-text="近設計">
              <span>Application Familiarity</span>
            </h1>
            <Tabs
              defaultActiveKey="webapps"
              id="applications-tab"
              className="mb-3"
              justify
            >
              <Tab eventKey="webapps" title="Web Apps">
                {webAppProjects.map((project) => (
                  <ProjectCard key={project.Name} {...project} />
                ))}
              </Tab>
              <Tab eventKey="admin" title="Administrative">
                {adminProjects.map((project) => (
                  <ProjectCard key={project.Name} {...project} />
                ))}
              </Tab>
              <Tab eventKey="manage" title="Management">
                {managementProjects.map((project) => (
                  <ProjectCard key={project.Name} {...project} />
                ))}
              </Tab>
              <Tab eventKey="desktop" title="Desktop Apps">
                {desktopAppProjects.map((project) => (
                  <ProjectCard key={project.Name} {...project} />
                ))}
              </Tab>
            </Tabs>
          </div>

          {/* Time Passers */}
          <div className="p-2">
            <h1 className="hero glitch layers" data-text="近設計">
              <span>Time Passers</span>
            </h1>
            <Tabs
              defaultActiveKey="graphics"
              id="other-tab"
              className="mb-3"
              justify
            >
              <Tab eventKey="graphics" title="Graphic Design">
                {graphicDesignProjects.map((project) => (
                  <ProjectCard key={project.Name} {...project} />
                ))}
              </Tab>
              <Tab eventKey="video" title="Video Editing">
                {videoEditingProjects.map((project) => (
                  <ProjectCard key={project.Name} {...project} />
                ))}
              </Tab>
              <Tab eventKey="misc" title="Other">
                {miscProjects.map((project) => (
                  <ProjectCard key={project.Name} {...project} />
                ))}
              </Tab>
            </Tabs>
          </div>
        </Stack>
      </div>
      <Footer />
    </>
  );
}

export default Projects;